<template>
  <div>
    <v-toolbar color="#1a1a27" class="text-white rounded-0" height="80px">
      <img v-if="asideEnabled" :src="siteLogo()" alt="Logo" width="75" />
      <v-spacer v-if="asideEnabled"></v-spacer>
      <v-toolbar-title class="ml-3">
        <h2 class="mb-0">Anasayfa</h2>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <KTTopbar></KTTopbar>
    </v-toolbar>
    <div class="d-flex flex-wrap">
      <v-col cols="6" md="3">
        <router-link
          :to="{ name: 'user-offers-list' }"
          class="text-decoration-none"
        >
          <v-card color="#1a1a27" class="pa-5" rounded="xl" dark elevation="4">
            <div class="d-flex justify-content-center">
              <v-icon class="mb-2" color="#ef5350" size="82">mdi-flash</v-icon>
            </div>
            <v-card-text class="text-center">
              <h6>İşlemlerim</h6>
            </v-card-text>
          </v-card>
        </router-link>
      </v-col>
      <v-col cols="6" md="3">
        <router-link
          :to="{ name: 'user-payments-list' }"
          class="text-decoration-none"
        >
          <v-card color="#1a1a27" class="pa-5" rounded="xl" dark elevation="4">
            <div class="d-flex justify-content-center">
              <v-icon class="mb-2" color="#ef5350" size="82"
                >mdi-checkbox-marked-circle</v-icon
              >
            </div>
            <v-card-text class="text-center">
              <h6>Ödemelerim</h6>
            </v-card-text>
          </v-card>
        </router-link>
      </v-col>
      <v-col cols="6" md="3">
        <router-link :to="{ name: 'EditProfile' }" class="text-decoration-none">
          <v-card color="#1a1a27" class="pa-5" rounded="xl" dark elevation="4">
            <div class="d-flex justify-content-center">
              <v-icon class="mb-2" color="#ef5350" size="82"
                >mdi-settings</v-icon
              >
            </div>
            <v-card-text class="text-center">
              <h6>Profilim</h6>
            </v-card-text>
          </v-card>
        </router-link>
      </v-col>
      <v-col cols="6" md="3">
        <router-link
          :to="{ name: 'user-documents-list' }"
          class="text-decoration-none"
        >
          <v-card color="#1a1a27" class="pa-5" rounded="xl" dark elevation="4">
            <div class="d-flex justify-content-center">
              <v-icon class="mb-2" color="#ef5350" size="82"
                >mdi-file-document</v-icon
              >
            </div>
            <v-card-text class="text-center">
              <h6>Belgelerim</h6>
            </v-card-text>
          </v-card>
        </router-link>
      </v-col>
    </div>
  </div>
</template>

<script>
import KTTopbar from "@/view/layout/header/Topbar.vue";
import { mapGetters } from "vuex";
import objectPath from "object-path";

export default {
  name: "UserDashboard",
  components: { KTTopbar },
  computed: {
    ...mapGetters(["currentUser", "layoutConfig"]),
    isStudent() {
      if (this.currentUser?.roles?.length === 0) return true;
      else if (
        this.currentUser?.roles?.length > 0 &&
        this.currentUser?.roles[0]?.name === "student"
      ) {
        return true;
      } else return false;
    },
    asideEnabled() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    siteLogo() {
      const menuAsideLeftSkin = this.layoutConfig("brand.self.theme");
      // set brand logo
      const logoObject = this.layoutConfig("self.logo");

      let logo;
      if (typeof logoObject === "string") {
        logo = logoObject;
      }
      if (typeof logoObject === "object") {
        logo = objectPath.get(logoObject, menuAsideLeftSkin + "");
      }
      if (typeof logo === "undefined") {
        const logos = this.layoutConfig("self.logo");
        logo = logos[Object.keys(logos)[0]];
      }
      return process.env.BASE_URL + logo;
    },
  },
};
</script>
